angular
  .module('slf')
  .factory('IndividualsCache', function (ApiEndpoint) {
    var lastSaveId = '';
    var lastRoomLoaded = null;

    return {
      get: function (id) {
        return ApiEndpoint.get('individual', {
          id: id
        }).then(function (response) {
          lastRoomLoaded = response.data;
        });
      },

      getLastRoomLoaded: function () {
        return lastRoomLoaded;
      },

      getLastSaveId: function () {
        return lastSaveId;
      },

      add: function (roomData) {
        return ApiEndpoint.post('individuals', {
          data: roomData
        }).then(function (response) {
          lastSaveId = response.data.saveId;
        });
      }
    };
  });
