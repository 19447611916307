/* global io */

angular
  .module('slf')
  .factory('ApiSocket', function ($rootScope) {
    var token;
    var socket = io({
      path: '/socio',
      autoConnect: false,
      // next two lines: trying to fix connection failure after some time
      transports: ['websocket'],
      upgrade: false
    });

    function connect() {
      socket.io.opts.query = token ? 'token=' + token : null;
      socket.open();
    }

    function disconnect() {
      socket.close();
    }

    function emit(event, data, ack) {
      if (angular.isFunction(data)) {
        ack = data;
        data = null;
      }
      if (token) {
        data = data || {};
        data.token = token;
      }
      return socket.emit(event, data, function (res) {
        if (ack) {
          $rootScope.$apply(function () {
            ack(res);
          });
        }
      });
    }

    function interceptEvent(type, event, callback) {
      return socket[type](event, function (data, ack) {
        if (callback) {
          $rootScope.$apply(function () {
            callback(data, ack);
          });
        }
      });
    }

    function on(event, callback) {
      return interceptEvent('on', event, callback);
    }

    function once(event, callback) {
      return interceptEvent('once', event, callback);
    }

    function off(event, callback) {
      return socket.off(event, callback);
    }

    function setToken(t) {
      token = t;
    }

    function getId() {
      return socket.id;
    }

    return {
      connect: connect,
      disconnect: disconnect,
      on: on,
      once: once,
      off: off,
      emit: emit,
      setToken: setToken,
      getId: getId
    };
  });
