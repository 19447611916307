angular
  .module('slf')
  .factory('Spinner', function ($rootScope, $timeout) {
    var counter = 0;
    var timeoutPromise = null;
    $rootScope.spinner = false;
    $rootScope.spinnerWarning = false;

    function toggle(toggle) {
      if (toggle) {
        if (timeoutPromise !== null) {
          $timeout.cancel(timeoutPromise);
        }
        timeoutPromise = $timeout(function () {
          $rootScope.spinnerWarning = true;
        }, 8000);
      } else {
        $rootScope.spinnerWarning = false;
        $timeout.cancel(timeoutPromise);
        timeoutPromise = null;
      }
      counter += toggle ? 1 : -1;
      counter = Math.max(counter, 0);
      $rootScope.spinner = counter > 0;
    }

    return {
      start: function () {
        toggle(true);
      },
      stop: function () {
        toggle(false);
      }
    };
  });
