angular
  .module('slf')
  .component('slfStars', {
    bindings: {
      user: '<',
      stars: '<'
    },
    templateUrl: 'app/common/stars/stars.html',
    controller: function ($element) { // , Top10
      var $ctrl = this;

      function updateStep() {
        var stars = $ctrl.stars;
        if (angular.isUndefined(stars) && $ctrl.user) {
          stars = $ctrl.user.stars;
        }
        if (angular.isUndefined(stars)) {
          return;
        }
        var steps = [1000000, 145000, 140000, 135000, 130000, 110000, 90000, 87500, 85000, 82500, 80000, 77500, 75000, 72500, 70000, 67500, 65000, 62500, 60000, 57500, 55000, 52500, 50000, 47500, 45000, 42500, 40000, 37500, 35000, 32500, 30000, 27500, 25000, 22500, 20000, 17500, 15000, 12500, 10000, 9000, 8000, 7000, 6000, 5000, 4000, 3000, 2000, 1000, 750, 500, 100, 0];
        steps.some(function (step) {
          if (stars >= step) {
            $element.attr('data-step', step);
            return true;
          }
          return false;
        });

        $ctrl.actualStars = stars;
      }

      $ctrl.$onChanges = function () {
        updateStep();
      };
    }
  });
