angular
  .module('slf')
  .component('slfLoginForm', {
    templateUrl: 'app/common/login_form/login_form.html',
    controller: function ($rootScope, UserAuth) {
      var $ctrl = this;
      $ctrl.user = {};

      $ctrl.login = function () {
        UserAuth.login($ctrl.user)
          .then(function () {
            $ctrl.user = {};
            $ctrl.childForm.$setPristine();
            $ctrl.childForm.$setUntouched();
            $rootScope.showLoginForm = false;
          }, function () {
            $ctrl.errorMsg = 'Diese Benutzerdaten sind nicht korrekt.';
          });
      };
    }
  });
