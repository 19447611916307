angular
  .module('slf').directive('nextFocus', nextFocus);
function nextFocus() {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      elem.bind('keydown', function (e) {
        var code = e.keyCode || e.which;
        if (code === 13) {
          e.preventDefault();
          try {
            if (attrs.tabindex !== undefined) {
              var currentTabeIndex = attrs.tabindex;
              var nextTabIndex = parseInt(currentTabeIndex, 10) + 1;
              var elems = document.querySelectorAll('[tabindex]');
              for (var i = 0, len = elems.length; i < len; i++) {
                var el = angular.element(elems[i]);
                var idx = parseInt(el.attr('tabindex'), 10);
                if (idx === nextTabIndex) {
                  elems[i].focus();
                  break;
                }
              }
            }
          } catch (e) {
          }
        }
      });
    }
  };
}
