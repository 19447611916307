angular
  .module('slf')
  .component('roomResults', {
    templateUrl: 'app/room/results/results.html',
    require: {
      $roomCtrl: '^room'
    },
    controller: function (ModalWindow, UserAuth, Rooms, $timeout, $interval, $transitions, $state, $rootScope) {
      var $ctrl = this;

      var currentCategoryIndex;
      var timer;
      var timer2;
      var timerEnabled = true;

      var watcher2 = $transitions.onSuccess({to: 'game.room.results.category'}, function () {
        currentCategoryIndex = 0;
        $ctrl.currentCategory = [];
        $ctrl.showNextCategory(true);
        return true;
      });

      var watcher3 = $transitions.onSuccess({to: 'game.room.results.scoring'}, function () {
        $ctrl.scoresSorted = [];
        $timeout(function () {
          $ctrl.scoresSorted = $ctrl.$roomCtrl.room.results.scoresSorted;
        }, 100);
      });

      $ctrl.showNextCategory = function (timerTriggered) {
        $timeout.cancel(timer);
        if ($ctrl.$roomCtrl.room) {
          if (currentCategoryIndex >= $ctrl.$roomCtrl.room.results.answers.length) {
            $state.go('^.scoring');
            return;
          }

          $ctrl.currentCategory = $ctrl.$roomCtrl.room.results.answers[currentCategoryIndex];
          currentCategoryIndex++;

          if (!timerTriggered) {
            timerEnabled = false;
          }
        }
      };

      $ctrl.answerListFinished = function () {
        if (timerEnabled) {
          timer = $timeout(function () {
            $ctrl.showNextCategory(true);
          }, 5000);
        }
      };

      $ctrl.$onInit = function () {
        $ctrl.scores = $ctrl.$roomCtrl.room.results.scores;
        $state.go('game.room.results.category');

        if (!UserAuth.isAuthenticated()) {
          ModalWindow.confirm('Deine erspielten Sterne verfallen, sobald du das Spiel verlässt. Möchtest du dich einloggen, um deine Sterne dauerhaft zu speichern?', {
            declineText: 'ignorieren',
            confirmText: 'einloggen'
          }, function (confirmed) {
            if (confirmed) {
              $rootScope.showLoginForm = true;
            }
          });
        }

        $ctrl.waitingSeconds = 7;
        $ctrl.$roomCtrl.room.results.answers.forEach(function (category) {
          $ctrl.waitingSeconds += Rooms.categoryHasValidAnswers(category) ? 10 : 5;
        });

        timer2 = $interval(function () {
          $ctrl.waitingSeconds--;
        }, 1000, $ctrl.waitingSeconds);
      };

      $ctrl.$onDestroy = function () {
        watcher2();
        watcher3();
        $timeout.cancel(timer);
        $timeout.cancel(timer2);
      };
    }
  });
