angular
  .module('slf')
  .factory('Top10', function (ApiEndpoint) {
    var users = [];
    ApiEndpoint.get('top10').then(function (res) {
      if (res.data) {
        for (var n = 0; n < res.data.players.length; n++) {
          if (res.data.players[n].name !== 'schinkentoast') {
            users.push(res.data.players[n]);
          }
        }
      }
    });

    return {
      get: function () {
        return users;
      },

      getRankForName: function (name) {
        if (!angular.isArray(users)) {
          return;
        }
        for (var i = 0; i < users.length; i++) {
          try {
            if (users[i].name === name) {
              return i + 1;
            }
          } catch (e) {
          }
        }
        return 99999;
      }
    };
  });
