angular
  .module('slf')
  .component('slfSignUpForm', {
    templateUrl: 'app/common/sign_up_form/sign_up_form.html',
    controller: function (UserAuth) {
      var $ctrl = this;
      $ctrl.user = {};
      $ctrl.signedUp = false;

      $ctrl.register = function () {
        UserAuth.register($ctrl.user)
          .then(function () {
            $ctrl.user = {};
            $ctrl.signedUp = true;
          }, function () {
            $ctrl.errorMsg = 'Dieser Benutzername oder diese E-Mail-Adresse ist bereits registriert.';
          });
      };
    }
  });
