angular
  .module('slf')
  .component('createRoom', {
    templateUrl: 'app/create_room/create_room.html',
    controller: function (Rooms, UserAuth, CATEGORY_SETS, $state, IndividualsCache, ModalWindow) {
      var $ctrl = this;

      $ctrl.room = {
        name: '',
        maxPlayers: 3,
        minStars: 0,
        isPrivate: false,
        timePerRound: 30,
        numberOfCategories: '4',
        excludedCharacters: [],
        gameMode: 'leading',
        minLength: 3,
        categories: [],
        customCategories: []
      };

      $ctrl.roomSaveId = '';

      $ctrl.saveTestHelper = {};

      $ctrl.categorySets = angular.copy(CATEGORY_SETS);

      $ctrl.categories = [];
      $ctrl.customCategories = [];

      $ctrl.addValue = function ($event, key) {
        $ctrl.room[key] = $ctrl.room[key] || [];
        var value = $event.currentTarget.value;
        var index = $ctrl.room[key].indexOf(value);
        if (index < 0) {
          $ctrl.room[key].push(value);
        } else {
          $ctrl.room[key].splice(index, 1);
        }
      };

      $ctrl.toggle = function ($event, key) {
        $ctrl.room[key] = $event.currentTarget.checked;
      };

      $ctrl.toggleCategorySet = function (set) {
        set.checked = !set.checked;
        if (set.checked) {
          addCategories(set.categories);
        } else {
          removeCategories(set.categories);
        }
      };

      function addCategories(categories) {
        categories.forEach(function (category) {
          addCategory(category, false);
        });
      }

      function removeCategories(categories) {
        categories.forEach(function (category) {
          var index = $ctrl.categories.indexOf(category);
          $ctrl.categories.splice(index, 1);
          index = $ctrl.room.categories.indexOf(category);
          if (index > -1) {
            $ctrl.room.categories.splice(index, 1);
          }
        });
      }

      function addCategory(category, custom) {
        if ($ctrl.categories.indexOf(category) < 0 && $ctrl.customCategories.indexOf(category) < 0) {
          if (custom) {
            $ctrl.customCategories.push(category);
            $ctrl.room.customCategories.push(category);
          } else {
            $ctrl.categories.push(category);
            $ctrl.room.categories.push(category);
          }
        }
      }

      function validate(form) {
        $ctrl.errorMsg = '';
        if (!form.checkValidity()) {
          $ctrl.errorMsg = 'Bitte überprüfe noch einmal alle Felder.';
        } else if ($ctrl.room.categories.length + $ctrl.room.customCategories.length < 1) {
          $ctrl.errorMsg = 'Bitte wähle mindestens eine Kategorie aus.';
        } else {
          return true;
        }
        return false;
      }

      function setCategorySetEnabled(setId, isEnabled) {
        for (var n = 0; n < $ctrl.categorySets.length; n++) {
          if ($ctrl.categorySets[n].name === setId) {
            $ctrl.categorySets[n].checked = isEnabled;
            if (isEnabled) {
              addCategories($ctrl.categorySets[n].categories);
            }
          }
        }
      }

      function removeCustomCategory(category) {
        var idx = $ctrl.customCategories.indexOf(category);
        if (idx > -1) {
          $ctrl.customCategories.splice(idx, 1);
        }
        idx = $ctrl.room.customCategories.indexOf(category);
        if (idx > -1) {
          $ctrl.room.customCategories.splice(idx, 1);
        }
      }

      function getCopyFields() {
        return ['name', 'maxPlayers', 'name', 'minStars', 'timePerRound', 'numberOfCategories', 'gameMode', 'minLength'];
      }

      function loadRoomSettings(settings) {
        var fields = getCopyFields();
        var n = 0;
        for (n = 0; n < fields.length; n++) {
          $ctrl.room[fields[n]] = settings[fields[n]];
        }

        $ctrl.room.isPrivate = settings.isPrivate;
        document.getElementById('private').checked = settings.isPrivate;

        // clear excluded character list
        while ($ctrl.room.excludedCharacters.length > 0) {
          document.getElementById('exclude-' + $ctrl.room.excludedCharacters[0]).checked = false;
          $ctrl.room.excludedCharacters.splice(0, 1);
        }
        $ctrl.room.excludedCharacters = [];

        for (n = 0; n < settings.excludedCharacters.length; n++) {
          $ctrl.room.excludedCharacters.push(settings.excludedCharacters[n]);
          document.getElementById('exclude-' + settings.excludedCharacters[n]).checked = true;
        }

        while ($ctrl.room.categories.length > 0) {
          $ctrl.room.categories.splice(0, 1);
        }

        while ($ctrl.categories.length > 0) {
          $ctrl.categories.splice(0, 1);
        }

        for (n = 0; n < $ctrl.categorySets.length; n++) {
          setCategorySetEnabled($ctrl.categorySets[n].name, false);
        }

        for (n = 0; n < settings.categorySets.length; n++) {
          setCategorySetEnabled(settings.categorySets[n].name, settings.categorySets[n].checked ? true : false);
        }

        $ctrl.room.customCategories = [];
        $ctrl.customCategories = [];
        var unselectedCategories = [];
        for (n = 0; n < settings.customCategories.length; n++) {
          addCategory(settings.customCategories[n], true);
        }
        // find unselected categories and save them to an array
        for (n = 0; n < $ctrl.customCategories.length; n++) {
          if (settings.selectedCustomCategories.indexOf($ctrl.customCategories[n]) > -1) {
          } else {
            unselectedCategories.push($ctrl.customCategories[n]);
          }
        }

        while (unselectedCategories.length > 0) {
          $ctrl.room.customCategories.splice($ctrl.room.customCategories.indexOf(unselectedCategories[0]), 1);
          unselectedCategories.splice(0, 1);
        }

        unselectedCategories = [];
        for (n = 0; n < $ctrl.room.categories.length; n++) {
          if (settings.categories.indexOf($ctrl.room.categories[n]) > -1) {
          } else {
            unselectedCategories.push($ctrl.room.categories[n]);
          }
        }
        while (unselectedCategories.length > 0) {
          $ctrl.room.categories.splice($ctrl.room.categories.indexOf(unselectedCategories[0]), 1);
          unselectedCategories.splice(0, 1);
        }
      }

      function getFormData() {
        var roomSave = {};
        var n = 0;
        var fields = getCopyFields();
        for (n; n < fields.length; n++) {
          roomSave[fields[n]] = $ctrl.room[fields[n]];
        }

        roomSave.categories = angular.copy($ctrl.room.categories); // alle elemente die "checked" sind
        roomSave.customCategories = angular.copy($ctrl.customCategories);
        roomSave.selectedCustomCategories = angular.copy($ctrl.room.customCategories);
        roomSave.categorySets = [];
        roomSave.excludedCharacters = angular.copy($ctrl.room.excludedCharacters);
        roomSave.isPrivate = $ctrl.room.isPrivate;

        for (n = 0; n < $ctrl.categorySets.length; n++) {
          roomSave.categorySets.push({
            name: $ctrl.categorySets[n].name,
            checked: $ctrl.categorySets[n].checked
          });
        }
        return roomSave;
      }

      $ctrl.loadLocal = function () {
        loadRoomSettings(this.saveTestHelper);
      };

      $ctrl.saveLocal = function () {
        $ctrl.saveTestHelper = getFormData();
      };

      $ctrl.save = function () {
        $ctrl.errorMsg = '';
        IndividualsCache.add(getFormData())
          .then(function () {
            ModalWindow.alert('Ihr Raum wurde gespeichert. Um ihn später wieder zu laden, verwenden Sie bitte diesen Code:\n' + IndividualsCache.getLastSaveId(), function () {});
          }, function () {
            $ctrl.errorMsg = 'Die Daten konnten leider nicht gespeichert werden.';
          });
      };

      $ctrl.create = function () {
        var room = angular.copy($ctrl.room);
        room.excludedCharacters = room.excludedCharacters.join('').toLowerCase();
        room.minLength = room.minLength || 3;

        Rooms.create(room, function (success) {
          if (success) {
            $state.go('game.room');
          }
        });
      };

      $ctrl.submit = function ($event) {
        if ($ctrl.newCustomCategory) {
          addCategory($ctrl.newCustomCategory, true);
          $ctrl.newCustomCategory = '';
        } else if (validate($event.target)) {
          $ctrl.create();
        }
      };

      $ctrl.loadRoomRemplate = function () {
        $ctrl.loadErrorMsg = '';
        IndividualsCache.get(this.roomSaveId)
          .then(function () {
            loadRoomSettings(IndividualsCache.getLastRoomLoaded());
          }, function () {
            $ctrl.loadErrorMsg = 'Es wurde kein Spiel mit dieser Raumkennung gefunden.';
          });
      };

      if (!UserAuth.isAuthenticated()) {
        $state.go('game.lobby');
      }

      $ctrl.toggleCategorySet($ctrl.categorySets[0]);
    }
  });
