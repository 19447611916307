angular
  .module('slf')
  .component('roomInput', {
    templateUrl: 'app/room/input/input.html',
    require: {
      $roomCtrl: '^room'
    },
    controller: function ($rootScope, Sound) {
      var $ctrl = this;
      var room;
      var startTimestamp;
      var characterSound;
      var characterDrumRollId;
      var total;
      var progress;

      function validateWord(word) {
        var charMatches = false;
        word = word.toLowerCase();
        var char = room.currentCharacter.toLowerCase();
        switch (room.gameMode) {
          case 'leading':
            charMatches = word[0] === char;
            break;
          case 'trailing':
            charMatches = word[word.length - 1] === char;
            break;
          case 'containing':
            charMatches = word.indexOf(char) > -1;
            break;
          default:
        }
        var lengthMatches = word.length >= room.minLength;
        return charMatches && lengthMatches;
      }

      function getSecondsSinceStart() {
        return Math.round((Date.now() / 1000) - startTimestamp);
      }

      var watcher = $rootScope.$watch(function () {
        return $ctrl.categories;
      }, function () {
        $ctrl.total = 0;
        if (!$ctrl.categories) {
          return;
        }
        $ctrl.categories.map(function (category) {
          category.valid = validateWord(category.word);
          category.invalid = Boolean(category.word) && !category.valid;
          return category;
        });

        $ctrl.total = $ctrl.categories.length;
        $ctrl.progress = 0;
        for (var n = 0; n < $ctrl.categories.length; n++) {
          if ($ctrl.categories[n].valid === true) {
            $ctrl.progress++;
          }
        }
        if ($ctrl.progress > 0) {
          $rootScope.lastProgress = Date.now();
        }
        $rootScope.totalLabel = $ctrl.progress + ' / ' + $ctrl.total;
      }, true);

      $ctrl.charRevealed = function () {
        characterSound.stop(characterDrumRollId);
        characterSound.play('end');
      };

      $ctrl.charAnimationFinished = function () {
        $ctrl.$roomCtrl.showCurrentCharacter = true;
        $ctrl.categories = room.currentCategories;
        $ctrl.$roomCtrl.startTimer(room.timePerRound - getSecondsSinceStart());
      };

      $ctrl.updateTimestamp = function (category) {
        category.timestamp = getSecondsSinceStart();
      };

      $ctrl.playValidationSound = function (category) {
        if (category.invalid) {
          Sound.get('invalid').play();
        }
      };

      $ctrl.$onInit = function () {
        startTimestamp = Date.now() / 1000;
        room = $ctrl.$roomCtrl.room;
        $ctrl.$roomCtrl.showCurrentCharacter = false;

        characterSound = Sound.get('character');
        characterDrumRollId = characterSound.play('loop');
      };

      $ctrl.$onDestroy = function () {
        characterSound.stop(characterDrumRollId);
        watcher();
      };
    }
  });
