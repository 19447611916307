angular
  .module('slf')
  .component('lobby', {
    templateUrl: 'app/lobby/lobby.html',
    controller: function (Rooms, UserSettings, ModalWindow, $rootScope, $state, $filter) {
      var $ctrl = this;

      function join(room) {
        var alert;
        if (room.isPrivate) {
          alert = ModalWindow.wait('Bitte warte einen Moment, bis der Raumersteller deine Anfrage beantwortet.');
        }
        Rooms.join(room, function (success) {
          if (alert) {
            alert();
          }
          if (success) {
            $state.go('game.room');
          } else if (room.isPrivate) {
            ModalWindow.alert('Leider wurde deine Anfrage abgelehnt.');
          }
        });
      }

      var watcher = $rootScope.$watch(function () {
        return Rooms.getAll();
      }, function (r) {
        var rooms = [];
        // eslint-disable-next-line guard-for-in
        for (var id in r) {
          rooms.push(r[id]);
        }

        var systemRooms = $filter('filter')(rooms, {owner: 'system'});
        $ctrl.rooms = {};
        $ctrl.filters.forEach(function (filter) {
          if (filter === $ctrl.customFilter) {
            $ctrl.rooms[$ctrl.customFilter] = $filter('filter')(rooms, {owner: '!system'});
          } else {
            $ctrl.rooms[filter] = $filter('filter')(systemRooms, {name: filter});
          }
        });
      }, true);

      $ctrl.join = function (room) {
        if ($ctrl.cannotJoin(room)) {
          var msg;
          if (!$ctrl.isAuthorizedToJoin(room)) {
            msg = 'Für individuelle Räume musst du eingeloggt sein.';
          } else if (!$ctrl.isQualifiedToJoin(room)) {
            msg = 'Leider hast du nicht genügend Sterne, um in diesem Raum mitzuspielen.';
          } else {
            msg = 'Leider ist dieser Raum derzeit voll.';
          }
          ModalWindow.alert(msg);
          return;
        } else if (room.isPrivate) {
          ModalWindow.confirm('Dieser Raum ist privat. Möchtest du den Raumersteller um Zutritt bitten?', {
            declineText: 'nicht anfragen',
            confirmText: 'anfragen'
          }, function (confirmed) {
            if (confirmed) {
              join(room);
            }
          });
        } else {
          join(room);
        }
      };

      $ctrl.joinRandomly = function () {
        Rooms.joinRandomly(function () {
          $state.go('game.room');
        });
      };

      $ctrl.isAuthorizedToJoin = function (room) {
        return room.owner === 'system' || $rootScope.isAuthenticated;
      };

      $ctrl.isQualifiedToJoin = function (room) {
        return room.minStars <= $rootScope.user.stars;
      };

      $ctrl.cannotJoin = function (room) {
        return !$ctrl.isAuthorizedToJoin(room) || !$ctrl.isQualifiedToJoin(room) || room.players.length >= room.maxPlayers;
      };

      $ctrl.$onDestroy = function () {
        watcher();
      };

      $ctrl.customFilter = 'Individuell';
      $ctrl.filters = ['Training', 'Start', 'Semi', 'Profi', $ctrl.customFilter];
      $ctrl.currentFilter = UserSettings.get('lastRoomFilter', 'Training');

      $ctrl.chooseFilter = function (filter) {
        $ctrl.currentFilter = filter;
        UserSettings.set('lastRoomFilter', filter);
      };
    }
  });
