angular
  .module('slf')
  .component('highscore', {
    templateUrl: 'app/highscore/highscore.html',
    controller: function (ApiEndpoint, Top10) {
      var $ctrl = this;
      $ctrl.positionsPerPage = 15;

      $ctrl.showPage = function (page) {
        $ctrl.page = page;
        $ctrl.start = $ctrl.positionsPerPage * $ctrl.page;

        ApiEndpoint
          .get('highscore', {start: $ctrl.start, limit: $ctrl.positionsPerPage})
          .then(function (response) {
            $ctrl.positions = response.data.players;
            for (var n = 0; n < $ctrl.positions.length; n++) {
              $ctrl.positions[n].rank = Top10.getRankForName($ctrl.positions[n].name);
            }
          });
      };

      $ctrl.showPage(0);
    }
  });
