angular
  .module('slf')
  .component('splash', {
    templateUrl: 'app/splash/splash.html',
    controller: function ($state, $rootScope, $timeout) {
      this.login = function () {
        $state.go('game.lobby');
        $timeout(function () {
          $rootScope.showLoginForm = true;
        });
      };
    }
  });
