angular
  .module('slf')
  .factory('UserSettings', function ($cookies) {
    var settings = $cookies.getObject('settings') || {};

    return {
      get: function (key, deflt) {
        return settings[key] || deflt;
      },
      set: function (key, value) {
        settings[key] = value;
        $cookies.putObject('settings', settings);
      }
    };
  });
