angular
  .module('slf')
  .factory('ApiEndpoint', function (Spinner, $http) {
    function makeRequest(endpoint, method, data, params) {
      Spinner.start();
      return $http({
        method: method,
        url: '/api/v1/' + endpoint,
        data: data,
        params: params
      }).finally(function () {
        Spinner.stop();
      });
    }

    return {
      get: function (endpoint, params) {
        return makeRequest(endpoint, 'GET', null, params);
      },
      post: function (endpoint, data, params) {
        return makeRequest(endpoint, 'POST', data, params);
      },
      delete: function (endpoint, data, params) {
        return makeRequest(endpoint, 'DELETE', data, params);
      }
    };
  });
