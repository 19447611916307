angular
  .module('slf')
  .filter('categoryNumberRange', function () {
    return function (numbers) {
      var range = '';
      var lastRangeStart = null;
      var lastRangeNumber = null;

      function addNumberToRange() {
        if (lastRangeNumber !== lastRangeStart) {
          range += '–' + lastRangeNumber;
        }
      }

      numbers.forEach(function (number, i) {
        if (i === 0) {
          lastRangeStart = number;
          range += number;
        } else if (lastRangeNumber + 1 !== number) {
          addNumberToRange();
          lastRangeStart = number;
          range += ', ' + number;
        }
        lastRangeNumber = number;
      });

      addNumberToRange();

      return range;
    };
  });
