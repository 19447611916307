angular
  .module('slf')
  .component('slfNavBar', {
    templateUrl: 'app/common/nav_bar/nav_bar.html',
    controller: function (UserAuth, Users, Rooms, Sound, ModalWindow, $rootScope) {
      var $ctrl = this;

      function logout() {
        Rooms.leaveCurrent();
        UserAuth.logout();
      }

      var watcher = $rootScope.$watch(function () {
        return Users.getNumberOfOnline();
      }, function (number) {
        $ctrl.numberOfPlayers = number;
      });

      var watcher2 = $rootScope.$watch(function () {
        return Sound.getMuted();
      }, function (muted) {
        $ctrl.muted = muted;
      });

      $ctrl.logout = function () {
        if (Rooms.getCurrent()) {
          ModalWindow.confirm('Möchtest du dich wirklich ausloggen? Dadurch verlässt du auch das aktuelle Spiel.', {
            declineText: 'nicht ausloggen',
            confirmText: 'ausloggen',
            confirmColor: 'red'
          }, function (confirmed) {
            if (confirmed) {
              logout();
            }
          });
        } else {
          logout();
        }
      };

      $ctrl.toggleMuted = function () {
        Sound.toggleMuted();
      };

      $ctrl.$onDestroy = function () {
        watcher();
        watcher2();
      };
    }
  });
