angular
  .module('slf')
  .factory('UserAuth', function (ApiEndpoint, ApiSocket, Spinner, $cookies) {
    var currentUser = {authenticated: false, stars: 0};
    var token = $cookies.get('token');

    ApiSocket.setToken(token);
    Spinner.start();
    ApiSocket.connect();

    ApiSocket.on('connect', function () {
      socketConnected();
      if (token) {
        ApiSocket.once('update', function (data) {
          var user = data.players[currentUser.id];
          if (user) {
            currentUser.name = user.name;
            currentUser.stars = user.stars;
            currentUser.authenticated = true;
          } else {
            logout(false);
          }
        });
      }
    });

    ApiSocket.on('error', function () {
      logout(false);
      ApiSocket.disconnect();
      ApiSocket.connect();
    });

    function setToken(t) {
      token = t;
      ApiSocket.setToken(token);
      if (token) {
        $cookies.put('token', token);
      } else {
        $cookies.remove('token');
      }
    }

    function socketConnected() {
      currentUser.id = ApiSocket.getId();
      Spinner.stop();
    }

    function identifyWithToken() {
      if (!token) {
        return;
      }
      Spinner.start();
      ApiSocket.emit('login', function (data) {
        if (data.success) {
          currentUser.name = data.data.name;
          currentUser.stars = data.data.stars;
          currentUser.authenticated = true;
          $cookies.put('token', token);
        } else {
          logout(false);
        }
        Spinner.stop();
      });
    }

    function logout(notifyBackend) {
      if (notifyBackend !== false) {
        ApiEndpoint.delete('logout', null, {token: token}).then(function (res) {
          if (res.data.hash) {
            ApiSocket.emit('logout', {hash: res.data.hash});
          }
        });
      }

      setToken(null);
      currentUser.name = null;
      currentUser.stars = 0;
      currentUser.authenticated = false;
    }

    return {
      getAuthenticatedUser: function () {
        return currentUser;
      },

      isAuthenticated: function () {
        return currentUser.authenticated;
      },

      login: function (user) {
        return ApiEndpoint.post('login', {
          name: user.name,
          password: user.password
        }).then(function (response) {
          setToken(response.data.token);
          identifyWithToken();
        });
      },

      logout: logout,

      register: function (user) {
        return ApiEndpoint.post('register', {
          username: user.name,
          email: user.email,
          password: user.password
        });
      },

      resetPassword: function (email) {
        return ApiEndpoint.get('password', {email: email});
      }
    };
  });
