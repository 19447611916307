angular
  .module('slf')
  .component('slfPlayerList', {
    templateUrl: 'app/common/player_list/player_list.html',
    controller: function (Rooms, ChatManager, ApiSocket, $rootScope, Top10) {
      var $ctrl = this;

      var watcher = $rootScope.$watch(function () {
        return Rooms.getCurrent();
      }, function (room) {
        $ctrl.players = room ? room.players : null;
        if ($ctrl.players !== null && typeof ($ctrl.players) !== 'undefined') {
          for (var n = 0; n < $ctrl.players.length; n++) {
            $ctrl.players[n].rank = Top10.getRankForName($ctrl.players[n].name);
          }
        }
      }, true);

      var watcher2 = $rootScope.$watch(function () {
        return ApiSocket.getId();
      }, function (self) {
        $ctrl.self = self;
      });

      $ctrl.showMuteDialog = function (user) {
        ChatManager.showMuteDialogForUser(user);
      };

      $ctrl.$onDestroy = function () {
        watcher();
        watcher2();
      };
    }
  });
