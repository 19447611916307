angular
  .module('slf')
  .factory('ModalWindow', function () {
    var queue = [];
    var current;

    function updateCurrent() {
      current = queue.shift();
    }

    function addToQueue(type, message, callback, additional) {
      if (!(message instanceof Array)) {
        message = [message];
      }

      var item = {
        type: type,
        message: message,
        callback: callback
      };
      angular.extend(item, additional);
      queue.push(item);

      if (!current) {
        updateCurrent();
      }

      return function () {
        if (current === item) {
          updateCurrent();
        } else {
          var index = queue.indexOf(item);
          if (item > -1) {
            queue.splice(index, 1);
          }
        }
      };
    }

    function alert(message, callback) {
      return addToQueue('alert', message, callback);
    }

    function confirm(message, additional, callback) {
      return addToQueue('confirm', message, callback, additional);
    }

    function confirmOrBlock(message, additional, callback) {
      return addToQueue('block', message, callback, additional);
    }

    function wait(message) {
      return addToQueue('wait', message);
    }

    function acknowledged(confirmed) {
      if (current.callback) {
        current.callback(confirmed);
      }
      updateCurrent();
    }

    return {
      alert: alert,
      confirm: confirm,
      confirmOrBlock: confirmOrBlock,
      wait: wait,
      acknowledged: acknowledged,
      getCurrent: function () {
        return current;
      }
    };
  })

  .component('slfModalWindow', {
    transclude: true,
    templateUrl: 'app/common/modal_window/modal_window.html',
    controller: function ($rootScope, $element, ModalWindow) {
      var $ctrl = this;

      var watcher = $rootScope.$watch(function () {
        return ModalWindow.getCurrent();
      }, function (current) {
        if (current) {
          $ctrl.current = current;
        }
        $element.toggleClass('visible', Boolean(current));
      });

      this.$onDestroy = function () {
        watcher();
      };

      $ctrl.btnClicked = function (acknowledged) {
        ModalWindow.acknowledged(acknowledged);
      };
    }
  });
