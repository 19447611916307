angular
  .module('slf')
  .constant('CATEGORY_SETS', [
    {
      name: 'Standard',
      categories: ['Stadt', 'Land', 'Fluss', 'Hobby', 'Beruf', 'Pflanze', 'Tier', 'Farbe']
    },
    {
      name: 'Entertainment',
      categories: ['Comedians', 'Modehersteller', 'Schauspieler (männl.)', 'Schauspielerin', 'Musik-Band', 'Sänger (männl.)', 'Sängerin', 'TV-Sender', 'Zeichentrickfigur', 'Komponist', 'Zeitung / Zeitschrift', 'Volkslied', 'TV-Werbespot', 'Soap', 'Sternbild', 'Musikinstrument', 'TV-Sendung', 'Schlagerduo', 'Moderator', 'Promi - den ich mag', 'Kinofilm', 'Luxusmarke', 'Bestseller', 'Promi - der nervt', 'Comicfigur', 'Film- / Serienheld', 'Musikrichtung', 'Popsong', 'Spielfilm', 'Sänger / in', 'Fernsehserie', 'Bühnenstück', 'Oldie-Song', 'Märchen']
    },
    {
      name: 'Fun',
      categories: ['Strandutensilien', 'Tattoo-Motive', 'schönster Gewinn', 'Party-Motto', 'Sammelleidenschaft', 'Gesetz / Verbot', 'Motiv für Urlaubsfoto', 'Blöde Erfindung', 'Spielzeug', 'Unverzichtbares', 'Was noch nicht erfunden wurde', 'Schlimmes Geschenk', 'Ein Grund zum Feiern', 'Hässliches', 'Kirmesattraktion', 'Wichtiges Ereignis', 'Wovor man sich ekelt', 'Worüber man lachen muss', 'Typisch männliche Eigenschaft', 'Typisch weibliche Eigenschaft', 'Schönster Ort für Liebeserklärung', 'Ausrede für Verspätung', 'Gesellschaftliches Ereignis']
    },
    {
      name: 'Nahrung',
      categories: ['Getränk', 'Weinsorten', 'Suppe', 'Heißgetränk', 'Gewürz', 'Teesorte', 'Gebäck', 'Cocktail', 'Eissorte', 'Küchengerät', 'Küchenutensilien', 'Essbares', 'Brotbeläge', 'Etwas das sprudelt', 'Süßigkeit', 'Alkoholisches Getränk', 'Käsesorten', 'Speisen / Gerichte', 'Biersorte', 'Gemüse']
    },
    {
      name: 'Personen',
      categories: ['Vorname', 'Dichter / Schriftsteller', 'Maler', 'Wissenschaftler / Forscher', 'Politiker', 'Kosename', 'Märchenfigur', 'Historische Person', 'Staatschef / in', 'Prominenter Brillenträger', 'peinlicher Mädchenname', 'peinlicher Jungenname', 'Romanfigur', 'Adelige', 'Amerika. Präsidenten', 'männlicher Vorname', 'weiblicher Vorname']
    },
    {
      name: 'Sport',
      categories: ['Sportart', 'Sportler (männl.)', 'Sportlerin', 'Turnübung', 'Sportgeräte', 'Ballsportarten', 'Fußballverein', 'Olymp. Disziplinen', 'Sportler / in']
    },
    {
      name: 'Bonus',
      categories: ['Fremdwort', 'Verkehrsmittel', 'Möbel', 'Schmuck', 'Kinderspiel', 'Forschungsgebiet', 'Schreibgeräte', 'Sitzgelegenheit', 'Badeutensilien', 'Kopfbedeckung', 'Meteorologisches', 'Maßeinheit', 'Kleidungsstil', 'Rechenarten', 'Berühmtes Bauwerk', 'Kunstwerk', 'Tätigkeit im Haushalt', 'VHS-Kurs', 'Baumaterial', 'Feiertag', 'Buchtitel', 'Werkzeug', 'Tanz', 'Schulfach', 'Parfum', 'Handtaschen-Inhalt', 'Portemonnaie-Inhalt Fluggesellschaft', 'Automarke', 'Büro-Utensilien', 'Gesellschaftsspiel']
    },
    {
      name: 'Biologie',
      categories: ['Krankheit', 'Kosmetikartikel', 'Körperteil', 'Säugetier', 'Vogel', 'Insekt', 'Fisch', 'Baum', 'Blume', 'Hunderasse', 'Tierlaute', 'Typischer Haustiername', 'Allergien', 'Haarfarbe']
    },
    {
      name: 'Geographie',
      categories: ['Hauptstadt', 'Amerik. Bundesstaat', 'See', 'Berg', 'Insel', 'Sprache', 'Urlaubsziel', 'Sehenswürdigkeit', 'Gewässer', 'Berühmte Orte']
    }
  ]);
