angular
  .module('slf')
  .factory('ChatManager', function (ApiSocket) {
    var messages = {
      lobby: [],
      room: []
    };
    var currentMessages = messages.lobby;
    var mutedUsers = [];
    var muteDialogUser = null;

    ApiSocket.on('chatMessage', function (data) {
      data.self = ApiSocket.getId() === data.userId;
      if (data.self || mutedUsers.indexOf(data.userId) === -1) {
        currentMessages.push(data);
        if (currentMessages.length > 250) {
          currentMessages.shift();
        }
      }
    });

    return {

      sendLocal: function (userId, message) {
        currentMessages.push({self: true, content: message, userId: userId, cid: userId});
      },

      getMessages: function () {
        return currentMessages;
      },

      send: function (content) {
        if (content && (content.length > 0 || Boolean(content.trim()))) {
          ApiSocket.emit('chatMessage', {content: content});
        }
      },

      muteUser: function (userId) {
        mutedUsers.push(userId);
      },

      enterRoom: function () {
        currentMessages = messages.room;
      },

      leaveRoom: function () {
        currentMessages = messages.lobby;
        messages.lobby = [];
        messages.room = [];
      },

      showMuteDialogForUser: function (user) {
        muteDialogUser = user;
      },

      getMuteDialogUser: function () {
        return muteDialogUser;
      }
    };
  });
