angular
  .module('slf')
  .component('room', {
    templateUrl: 'app/room/room.html',
    controller: function (Rooms, ChatManager, ModalWindow, Sound, $state, $transitions, $rootScope, $interval, $timeout, ApiSocket) {
      var $ctrl = this;

      var timer;
      var hurrySound = Sound.get('hurry');
      var requestSound = Sound.get('request');
      var blockList = [];

      $rootScope.lastProgress = Date.now();

      $ctrl.autoExitTimeout = null;

      $ctrl.isOwner = function () {
        var result = false;
        var room = Rooms.getCurrent();
        if (room && room.owner !== 'system') {
          var ownerSid = room.owner;
          if (ownerSid === ApiSocket.getId()) {
            result = true;
          }
        }
        return result;
      };

      $ctrl.startTimer = function (time, callback) {
        $ctrl.stopTimer();
        $ctrl.timeRemaining = time;
        timer = $interval(function () {
          $ctrl.timeRemaining--;
          if ($ctrl.timeRemaining === 5) {
            hurrySound.play('loop');
          } else if ($ctrl.timeRemaining <= 0) {
            if ((!$ctrl.isOwner()) && (Date.now() - $rootScope.lastProgress) > 25 * 60 * 1000) {
              $rootScope.lastProgress = Date.now();
              $ctrl.autoExitTimeout = $timeout(function () {
                ModalWindow.acknowledged(true);
                $ctrl.room = null;
              }, 30000);
              ModalWindow.confirm('Du hast seit 15 Minuten keine Antworten mehr gegeben. In 30 Sekunden verlässt Du den Raum automatisch.', {
                declineText: 'bleiben',
                confirmText: 'verlassen',
                confirmColor: 'red'
              }, function (confirmed) {
                if (confirmed) {
                  Rooms.leaveCurrent();
                  $ctrl.room = null;
                }
                $timeout.cancel($ctrl.autoExitTimeout);
                $ctrl.autoExitTimeout = null;
                if (callback) {
                  callback(confirmed);
                }
              });
            }
            hurrySound.stop();
            if (callback) {
              callback();
            }
          }
        }, 1000, $ctrl.timeRemaining);
      };

      $ctrl.stopTimer = function () {
        $interval.cancel(timer);
        $ctrl.timeRemaining = null;
        hurrySound.stop();
      };

      $ctrl.stopTimer();

      $ctrl.showCurrentCharacter = true;
      $rootScope.gameState = '';

      var watcher = $rootScope.$watch(function () {
        return Rooms.getCurrent();
      }, function (room) {
        $ctrl.room = room;
      });

      var watcher2 = $rootScope.$watch(function () {
        return $ctrl.room ? $ctrl.room.state : null;
      }, function (state) {
        if (state) {
          $rootScope.gameState = state;
          if (!$ctrl.room.participating) {
            state = 'waiting';
          }
          $state.go('game.room.' + state);
        } else {
          $state.go('game.lobby');
        }
      });

      var watcher3 = $transitions.onBefore({exiting: 'game.room'}, function (transition) {
        if ($ctrl.room && !transition.options().custom.force) {
          $ctrl.leave(function (confirmed) {
            if (confirmed) {
              $state.go(transition.to().name);
            }
          });
          return false;
        }
        return true;
      });

      var joinApprovalTimeout;
      var joinApprovalModal;
      var watcher4 = Rooms.onPlayerWantsToJoin(function (user, ack) {
        var found = false;
        for (var n = 0; n < blockList.length; n++) {
          if (blockList[n].name === user.name) {
            found = true;
          }
        }
        if (found) {
          ack({approved: false});
        } else
        if (user.name === 'schinkentoast' || user.name === 'Fischbroetchen72') {
          ack({approved: true});
          return;
        } else {
          requestSound.play();
          joinApprovalModal = ModalWindow.confirmOrBlock('„' + user.name + '“ (' + user.stars + '★) bittet um Zutritt zu diesem Raum. Möchtest du diese Anfrage akzeptieren?', {
            declineText: 'ablehnen',
            declineColor: 'red',
            confirmText: 'akzeptieren',
            confirmColor: 'green',
            blockText: 'blockieren',
            blockColor: 'grey'
          }, function (confirmed) {
            $timeout.cancel(joinApprovalTimeout);
            if (confirmed === null) {
              ModalWindow.confirm('Bist du dir sicher, dass du Nutzer ' + user.name + ' für die Dauer deiner aktuellen Sitzung blockieren möchtest?', {
                declineText: 'nein, abbrechen',
                declineColor: 'red',
                confirmText: 'ja, blockieren',
                confirmColor: 'green'
              }, function (confirmed) {
                blockList.push({name: user.name, stars: user.stars, id: user.id});
                ChatManager.sendLocal('id_system', 'Sie haben den Spieler ' + user.name + ' blockiert.');
                ack({approved: !confirmed});
              });
            } else {
              ack({approved: confirmed});
            }
          });

          joinApprovalTimeout = $timeout(function () {
            joinApprovalModal();
          }, 30000);
        }
      });

      $ctrl.$onInit = function () {
        ChatManager.enterRoom();
      };

      $ctrl.$onDestroy = function () {
        $ctrl.stopTimer();
        ChatManager.leaveRoom();
        watcher();
        watcher2();
        watcher3();
        watcher4();
        if (joinApprovalModal) {
          joinApprovalModal();
        }
        if (joinApprovalTimeout) {
          joinApprovalTimeout();
        }
      };

      $ctrl.leave = function (callback) {
        ModalWindow.confirm('Möchtest du den Raum wirklich verlassen?', {
          declineText: 'bleiben',
          confirmText: 'verlassen',
          confirmColor: 'red'
        }, function (confirmed) {
          if (confirmed) {
            Rooms.leaveCurrent();
            $ctrl.room = null;
          }
          if (callback) {
            callback(confirmed);
          }
        });
      };
    }
  });
