/* global Howler, Howl */

angular
  .module('slf')
  .factory('Sound', function (UserSettings) {
    var muted;
    var sounds = {};
    var options = {
      character: {
        sprite: {
          loop: [0, 255, true],
          end: [255, 1000]
        }
      },
      hurry: {
        sprite: {
          loop: [0, 2600, true]
        }
      }
    };

    setMuted(UserSettings.get('soundMuted', false));

    function get(name) {
      if (!sounds[name]) {
        var opts = angular.extend({}, options[name], {
          src: ['assets/sounds/' + name + '.mp3']
        });
        sounds[name] = new Howl(opts);
      }
      return sounds[name];
    }

    function setMuted(m) {
      muted = m;
      Howler.mute(muted);
      UserSettings.set('soundMuted', muted);
    }

    function toggleMuted() {
      setMuted(!muted);
    }

    return {
      toggleMuted: toggleMuted,
      getMuted: function () {
        return muted;
      },
      get: get
    };
  });
