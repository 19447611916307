angular
  .module('slf')
  .factory('Rooms', function (ApiSocket, Users, Spinner, $timeout, $filter) {
    var rooms = [];
    var current;
    var nonces = [];
    var waitingTimer;

    function setCurrentState(state) {
      $timeout.cancel(waitingTimer);
      current.state = state;
    }

    function categoryHasValidAnswers(category) {
      return $filter('notEmpty')(category.answers).length > 0;
    }

    ApiSocket.on('update', function (data) {
      if (data.rooms) {
        var oldRooms = rooms;
        rooms = data.rooms;
        if (current) {
          current = rooms[current.id];
        }

        Object.keys(rooms).forEach(function (id) {
          var room = rooms[id];
          var oldRoom = oldRooms[id] || {};
          room.minLength = room.minLength || 3;

          var players = [];
          if (room.players) {
            room.players.forEach(function (player) {
              players.push(Users.getById(player));
            });
          }
          room.players = players;

          room.state = oldRoom.state || 'waiting';
          room.participating = oldRoom.participating || false;
          room.participatingPlayers = oldRoom.participatingPlayers;
          room.currentCharacter = oldRoom.currentCharacter;
          room.currentCategories = oldRoom.currentCategories || [];
          room.gameMode = room.gameMode || 'leading';
          room.answers = oldRoom.answers || [];
          room.review = oldRoom.review;
          room.results = oldRoom.results;
        });
      }
    });

    ApiSocket.on('roundStarted', function (data) {
      setCurrentState('input');
      current.currentCharacter = data.character;
      current.currentCategories = data.categories.map(function (category) {
        return {name: category, word: ''};
      });
      current.participating = true;
      current.participatingPlayers = angular.copy(current.players);
    });

    ApiSocket.on('roundTimeUp', function () {
      var answers = [];
      nonces = [];
      current.currentCategories.forEach(function (category) {
        var nonce = Math.random().toString(16).substring(2);
        nonces.push(nonce);
        answers.push({
          category: category.name,
          value: category.word || '',
          timestamp: category.timestamp || 10000,
          nonce: nonce
        });
      });
      arguments[arguments.length - 1]({answers: answers});
    });

    ApiSocket.on('reviewStarted', function (data) {
      setCurrentState('review');
      current.review = data.answers;
      current.review.forEach(function (category) {
        category.answers.map(function (answer) {
          answer.vote = 0;
          answer.self = nonces.indexOf(answer.nonce) > -1;
          return answer;
        });
      });
    });

    ApiSocket.on('reviewTimeUp', function () {
      var review = current.review.map(function (category) {
        category.answers = category.answers.map(function (answer) {
          var _answer = {};
          _answer[answer.value] = answer.vote;
          return _answer;
        });
        return category;
      });
      arguments[arguments.length - 1]({answers: review});
    });

    ApiSocket.on('roundEnded', function (data) {
      setCurrentState('results');

      current.results = {answers: data.answers};
      current.results.scores = {};
      current.participatingPlayers.forEach(function (player) {
        current.results.scores[player.id] = 0;
      });

      current.results.answers.forEach(function (category) {
        category.answers.forEach(function (answer) {
          if (angular.isDefined(current.results.scores[answer.userId])) {
            current.results.scores[answer.userId] += answer.additionalScore;
          }
        });
      });

      current.results.scoresSorted = Object.keys(current.results.scores).sort(function (a, b) {
        return current.results.scores[b] - current.results.scores[a];
      }).map(function (userId) {
        return {
          player: Users.getById(userId),
          score: current.results.scores[userId]
        };
      });

      var waitingSeconds = 7;
      current.results.answers.forEach(function (category) {
        waitingSeconds += categoryHasValidAnswers(category) ? 10 : 5;
      });
      waitingTimer = $timeout(function () {
        setCurrentState('waiting');
      }, waitingSeconds * 1000);
    });

    return {
      getAll: function () {
        return rooms;
      },

      getCurrent: function () {
        return current;
      },

      create: function (room, callback) {
        function createdCallback(response) {
          var room = rooms[response.roomId];
          if (room) {
            room.participating = true;
            current = room;
            callback(true);
            Spinner.stop();
          }
          return Boolean(room);
        }

        Spinner.start();
        ApiSocket.emit('createRoom', room, function (response) {
          if (response.success) {
            if (!createdCallback(response)) {
              ApiSocket.once('update', function () {
                createdCallback(response);
              });
            }
          } else {
            callback(false);
            Spinner.stop();
          }
        });
      },

      join: function (room, callback) {
        ApiSocket.emit('joinRoom', {id: room.id}, function (response) {
          if (response.success) {
            current = room;
          }
          callback(response.success);
        });
      },

      joinRandomly: function (callback) {
        Spinner.start();
        ApiSocket.emit('joinRandomRoom', function (response) {
          if (response.success) {
            var room = rooms[response.roomId];
            if (room) {
              room.participating = false;
              current = room;
              callback();
            }
          }
          Spinner.stop();
        });
      },

      leaveCurrent: function () {
        if (current) {
          ApiSocket.emit('leaveRoom', {id: current.id});
          current.participating = false;
          setCurrentState(null);
          current = null;
        }
      },

      onPlayerWantsToJoin: function (callback) {
        ApiSocket.on('playerWantsToJoin', function (data, ack) {
          callback(Users.getById(data.userId), ack);
        });

        return function () {
          ApiSocket.off('playerWantsToJoin');
        };
      },

      categoryHasValidAnswers: categoryHasValidAnswers
    };
  });
