angular
  .module('slf')
  .component('roomReview', {
    templateUrl: 'app/room/review/review.html',
    require: {
      $roomCtrl: '^room'
    },
    controller: function (Rooms, $timeout) {
      var $ctrl = this;
      var room;
      var timer;

      function updateCurrentCategory(index) {
        $ctrl.currentCategory = room.review[index];

        var showNext = function () {
          if (++index < room.review.length) {
            updateCurrentCategory(index);
          } else {
            $ctrl.finished = true;
          }
        };
        if (Rooms.categoryHasValidAnswers($ctrl.currentCategory)) {
          $ctrl.$roomCtrl.startTimer(15, showNext);
        } else {
          $ctrl.$roomCtrl.stopTimer();
          $timeout.cancel(timer);
          timer = $timeout(showNext, 5000);
        }
      }

      function vote(answer, value) {
        if (answer.vote === value) {
          value = 0;
        }
        answer.vote = value;
      }

      $ctrl.upvote = function (answer) {
        vote(answer, 1);
      };

      $ctrl.downvote = function (answer) {
        vote(answer, -1);
      };

      $ctrl.$onInit = function () {
        room = $ctrl.$roomCtrl.room;
        updateCurrentCategory(0);
      };

      $ctrl.$onDestroy = function () {
        $timeout.cancel(timer);
        $ctrl.$roomCtrl.stopTimer();
      };
    }
  });
