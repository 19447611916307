angular
  .module('slf')
  .component('slfAnswerList', {
    bindings: {
      scores: '<',
      answers: '<',
      finished: '=?'
    },
    templateUrl: 'app/common/answer_list/answer_list.html',
    controller: function (Sound, $timeout, $filter) {
      var $ctrl = this;

      var answersYetToShow;
      var lastAnswer;
      var timer;

      function showNextAnswer() {
        lastAnswer = answersYetToShow.shift();
        if (lastAnswer) {
          lastAnswer.visibleUpvotes = [];
          lastAnswer.visibleDownvotes = [];
          $ctrl.visibleAnswers.push(lastAnswer);
          timer = $timeout(showNextVote, 200);
        } else if ($ctrl.finished) {
          $ctrl.finished();
        }
      }

      function showNextVote() {
        if (lastAnswer.upvotes > 0) {
          lastAnswer.upvotes--;
          lastAnswer.visibleUpvotes.push(true);
          playSound('upvote', 150);
        } else if (lastAnswer.downvotes > 0) {
          lastAnswer.downvotes--;
          lastAnswer.visibleDownvotes.push(true);
          playSound('downvote', 200);
        }
        if (lastAnswer.upvotes + lastAnswer.downvotes > 0) {
          timer = $timeout(showNextVote, 150);
        } else {
          timer = $timeout(showNextAnswer, 400);
        }
      }

      function playSound(name, delay) {
        $timeout(function () {
          Sound.get(name).play();
        }, delay);
      }

      $ctrl.$onChanges = function () {
        if (!$ctrl.answers) {
          return;
        }

        $ctrl.visibleAnswers = [];
        answersYetToShow = angular.copy($filter('notEmpty')($ctrl.answers));

        $timeout.cancel(timer);
        showNextAnswer();
      };

      $ctrl.$onDestroy = function () {
        $timeout.cancel(timer);
      };
    }
  });
