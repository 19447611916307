angular
  .module('slf')
  .component('emojiPicker', {
    templateUrl: 'app/common/emoji_picker/emoji_picker.html',
    controller: function ($rootScope, $document) {
      var $ctrl = this;
      var icons = [
        {
          title: 'Smilies',
          display: '😄',
          list: [
            {
              title: 'Lachendes Gesicht',
              char: '😄'
            },
            {
              title: 'Zwinkerndes Gesicht',
              char: '😉'
            },
            {
              title: 'Grimassen schneidendes Gesicht',
              char: '😬'
            },
            {
              title: 'Herzgesicht',
              char: '😍'
            },
            {
              title: 'Erstaunen',
              char: '😲'
            },
            {
              title: 'Angst',
              char: '😱'
            },

            {
              title: 'Übelkeit',
              char: '🤮'
            },
            {
              title: 'Weinendes Gesicht',
              char: '😢'
            },
            {
              title: 'Stark weinendes Gesicht',
              char: '😭'
            },
            {
              title: 'Rollende Augen',
              char: '🙄'
            },
            {
              title: 'Denkendes Gesicht',
              char: '🤔'
            },
            {
              title: 'Ausdrucksloses Gesicht',
              char: '😑'
            },

            {
              title: 'Gesicht mti Sonnenbrille',
              char: '😎'
            },
            {
              title: 'Heiligenschein',
              char: '😇'
            },
            {
              title: 'Sternaugen',
              char: '🤩'
            },
            {
              title: 'Partygesicht',
              char: '🥳'
            },
            {
              title: 'Bittende Augen',
              char: '🥺'
            },
            {
              title: 'ROTFL',
              char: '🤣'
            },

            {
              title: 'Clown',
              char: '🤡'
            },
            {
              title: 'Geist',
              char: '👻'
            },
            {
              title: 'Excellent',
              char: '👌'
            },
            {
              title: 'Händeklatschen',
              char: '👏'
            },
            {
              title: 'Daumen hoch',
              char: '👍'
            },
            {
              title: 'Daumen runter',
              char: '👎'
            }
          ]
        },
        {
          title: 'Tiere & Natur',
          display: '🐻',
          list: [
            {
              title: 'Hund',
              char: '🐶'
            },
            {
              title: 'Hamster',
              char: '🐹'
            },
            {
              title: 'Hase',
              char: '🐰'
            },
            {
              title: 'Fuchs',
              char: '🦊'
            },
            {
              title: 'Bär',
              char: '🐻'
            },
            {
              title: 'Panda',
              char: '🐼'
            },
            {
              title: 'Löwe',
              char: '🦁'
            },
            {
              title: 'Schwein',
              char: '🐷'
            },
            {
              title: 'Frosch',
              char: '🐸'
            },
            {
              title: 'Nicht sehender Affe',
              char: '🙈'
            },
            {
              title: 'Nichts sagender Affe',
              char: '🙊'
            },
            {
              title: 'Nichts hörender Affe',
              char: '🙉'
            },
            {
              title: 'Huhn',
              char: '🐔'
            },
            {
              title: 'Küken',
              char: '🐣'
            },
            {
              title: 'Pferd',
              char: '🐴'
            },
            {
              title: 'Einhorn',
              char: '🦄'
            },
            {
              title: 'Schlange',
              char: '🐍'
            },
            {
              title: 'Delfin',
              char: '🐬'
            },
            {
              title: 'Gorilla',
              char: '🦍'
            },
            {
              title: 'Schmetterling',
              char: '🦋'
            },
            {
              title: 'Schnecke',
              char: '🐌'
            },
            {
              title: 'Pfau',
              char: '🦚'
            },
            {
              title: 'Kleeblatt',
              char: '🍀'
            },
            {
              title: 'Rose',
              char: '🌹'
            }
          ]
        },
        {
          title: 'Essen und Trinken',
          display: '🍒',
          list: [
            {
              title: 'Apfel',
              char: '🍎'
            },
            {
              title: 'Birne',
              char: '🍐'
            },
            {
              title: 'Banane',
              char: '🍌'
            },
            {
              title: 'Erdbeere',
              char: '🍓'
            },
            {
              title: 'Kirsche',
              char: '🍒'
            },
            {
              title: 'Kokusnuss',
              char: '🥥'
            },

            {
              title: 'Tomate',
              char: '🍅'
            },
            {
              title: 'Möhre',
              char: '🥕'
            },
            {
              title: 'Brot',
              char: '🍞'
            },
            {
              title: 'Bagel',
              char: '🥯'
            },
            {
              title: 'Käse',
              char: '🧀'
            },
            {
              title: 'Pizza',
              char: '🍕'
            },

            {
              title: 'Schüssel & Stäbchen',
              char: '🍜'
            },
            {
              title: 'Sushi',
              char: '🍣'
            },
            {
              title: 'Kuchenstück',
              char: '🍰'
            },
            {
              title: 'Geburtstagstorte',
              char: '🎂'
            },
            {
              title: 'Bittende Augen',
              char: '🥺'
            },
            {
              title: 'Lutscher',
              char: '🍭'
            },

            {
              title: 'Popcorn',
              char: '🍿'
            },
            {
              title: 'Babyflasche',
              char: '🍼'
            },
            {
              title: 'Kaffee/Tee',
              char: '☕'
            },
            {
              title: 'Bier',
              char: '🍺'
            },
            {
              title: 'Sekt anstoßen',
              char: '🥂'
            },
            {
              title: 'Sekt',
              char: '🍾'
            }
          ]
        },
        {
          title: 'Aktivitäten',
          display: '⚽',
          list: [
            {
              title: 'Fußball',
              char: '⚽'
            },
            {
              title: 'Basketball',
              char: '🏀'
            },
            {
              title: 'Frisbee',
              char: '🥏'
            },
            {
              title: 'Billiard',
              char: '🎱'
            },
            {
              title: 'Golf',
              char: '⛳'
            },
            {
              title: 'Skateboard',
              char: '🛹'
            },

            {
              title: 'Snowboarder',
              char: '🏂'
            },
            {
              title: 'Gewichte heben',
              char: '🏋'
            },
            {
              title: 'Jockey',
              char: '🏇'
            },
            {
              title: 'Lotussitz',
              char: '🧘'
            },
            {
              title: 'Surfen',
              char: '🏄'
            },
            {
              title: 'Rudern',
              char: '🚣'
            },

            {
              title: 'Pokal',
              char: '🏆'
            },
            {
              title: 'Medaillie',
              char: '🥇'
            },
            {
              title: 'Klavier',
              char: '🎹'
            },
            {
              title: 'Gitarre',
              char: '🎸'
            },
            {
              title: 'Würfel',
              char: '🎲'
            },
            {
              title: 'Palette',
              char: '🎨'
            },

            {
              title: 'Computerspiele!',
              char: '👾'
            },
            {
              title: 'Volltreffer',
              char: '🎯'
            },
            {
              title: 'Slotmachine',
              char: '🎰'
            },
            {
              title: 'Puzzle',
              char: '🧩'
            },
            {
              title: 'Schlitten',
              char: '🛷'
            },
            {
              title: 'Theatermasken',
              char: '🎭'
            }
          ]
        },
        {
          title: 'Fortbewegung & Reisen',
          display: '🚗',
          list: [
            {
              title: 'PKW',
              char: '🚗'
            },
            {
              title: 'Polizei',
              char: '🚓'
            },
            {
              title: 'S-Bahn',
              char: '🚈'
            },
            {
              title: 'Rakete',
              char: '🚀'
            },
            {
              title: 'Ufo',
              char: '🛸'
            },
            {
              title: 'Anker',
              char: '⚓'
            },

            {
              title: 'Radfahren',
              char: '🚲'
            },
            {
              title: 'Insel',
              char: '🏝️'
            },
            {
              title: 'Wüste',
              char: '🏜️'
            },
            {
              title: 'Camping',
              char: '🏕️'
            },
            {
              title: 'Haus',
              char: '🏡'
            },
            {
              title: 'Wunderkerze',
              char: '🎇'
            },

            {
              title: 'Feuerwerk',
              char: '🎆'
            },
            {
              title: 'Klinik',
              char: '🏥'
            },
            {
              title: 'Kirche',
              char: '💒'
            },
            {
              title: 'Sonnenaufgang',
              char: '🌄'
            },
            {
              title: 'Sternschnuppe',
              char: '🌠'
            },
            {
              title: 'Skyline (Nacht)',
              char: '🌃'
            }
          ]
        },
        {
          title: 'Gegenstände',
          display: '📱',
          list: [
            {
              title: 'Handy',
              char: '📱'
            },
            {
              title: 'Laptop',
              char: '💻'
            },
            {
              title: 'Joystick',
              char: '🕹️'
            },
            {
              title: 'Fotoapparat',
              char: '📸'
            },
            {
              title: 'Telefonhörer',
              char: '📞'
            },
            {
              title: 'Kompass',
              char: '🧭'
            },

            {
              title: 'Wecker',
              char: '⏰'
            },
            {
              title: 'Sanduhr',
              char: '⏳'
            },
            {
              title: 'Geschenk',
              char: '🎁'
            },
            {
              title: 'Glühbirne',
              char: '💡'
            },
            {
              title: 'Taschenlampe',
              char: '🔦'
            },
            {
              title: 'Kerze',
              char: '🕯️'
            },

            {
              title: 'Konfetti',
              char: '🎉'
            },
            {
              title: 'Euro',
              char: '💶'
            },
            {
              title: 'Diamant',
              char: '💎'
            },
            {
              title: 'Geldsack',
              char: '💰'
            },
            {
              title: 'Ballon',
              char: '🎈'
            },
            {
              title: 'Teddy',
              char: '🧸'
            },

            {
              title: 'Schild',
              char: '🛡️'
            },
            {
              title: 'Volltreffer',
              char: '🎯'
            },
            {
              title: 'Zigarette',
              char: '🚬'
            },
            {
              title: 'Kristallkugel',
              char: '🔮'
            },
            {
              title: 'Tablette',
              char: '💊'
            },
            {
              title: 'Bett',
              char: '🛏️'
            }
          ]
        }
      ];
      $ctrl.emojies = icons;
      $rootScope.emojiPickerActive = false;

      $ctrl.lastFocused = null;
      $ctrl.selectedTab = 0;
      angular.element('input[type=\'text\']').focus(function () {
        $ctrl.lastFocused = $document[0].activeElement;
      });

      $ctrl.insertText = function (text) {
        var input = $ctrl.lastFocused;
        if (angular.isUndefined(input)) {
          return;
        }
        var scrollPos = input.scrollTop;
        var pos = 0;
        var selectionRange = -1;
        var browser = ((input.selectionStart || input.selectionStart === '0') ?
          'ff' : ($document[0].selection ? 'ie' : false));
        if (browser === 'ie') {
          input.focus();
          selectionRange = $document[0].selection.createRange();
          selectionRange.moveStart('character', -input.value.length);
          pos = selectionRange.text.length;
        } else
        if (browser === 'ff') {
          pos = input.selectionStart;
        }

        var front = (input.value).substring(0, pos);
        var back = (input.value).substring(pos, input.value.length);
        input.value = front + text + back;
        pos += text.length;
        if (browser === 'ie') {
          input.focus();
          selectionRange = $document[0].selection.createRange();
          selectionRange.moveStart('character', -input.value.length);
          selectionRange.moveStart('character', pos);
          selectionRange.moveEnd('character', 0);
          selectionRange.select();
        } else
        if (browser === 'ff') {
          input.selectionStart = pos;
          input.selectionEnd = pos;
          input.focus();
        }
        input.scrollTop = scrollPos;
        angular.element(input).trigger('input');
      };

      $ctrl.selectTab = function (tabIndex) {
        $ctrl.selectedTab = tabIndex;
      };

      $ctrl.selectEmoji = function (emoji) {
        $ctrl.insertText(emoji.char);
      };

      $ctrl.onTrigger = function () {
        $rootScope.emojiPickerActive = !$rootScope.emojiPickerActive;
      };

      $ctrl.$onDestroy = function () {
      };
    }
  });
