angular
  .module('slf')
  .component('slfCharacterAnimation', {
    bindings: {
      character: '=',
      revealed: '=?',
      finished: '=?'
    },
    template: '<div></div>',
    controller: function ($element, $timeout) {
      var $ctrl = this;

      var char;
      var firstRun;
      var timer;
      var timeStep;
      var $char;
      var animationTimeout;

      function updateChar() {
        $char.text(char);

        if (!firstRun && char === $ctrl.character) {
          $element.addClass('finished');

          if ($ctrl.revealed) {
            $ctrl.revealed();
          }

          // workaround for browsers that still don't support animationend event
          if ($ctrl.finished) {
            animationTimeout = $timeout($ctrl.finished, 1500);
          }

          return;
        }

        char = String.fromCharCode(char.charCodeAt(0) + 1);

        if (char > 'z') {
          char = 'a';
          firstRun = false;
        }

        timer = $timeout(updateChar, timeStep);
      }

      $ctrl.$onInit = function () {
        char = 'a';
        firstRun = true;
        $char = $element.find('div');

        updateChar();
      };

      $ctrl.$onChanges = function () {
        timeStep = 2000 / ($ctrl.character.charCodeAt(0) - 72);
      };

      $ctrl.$onDestroy = function () {
        $timeout.cancel(timer);
        $timeout.cancel(animationTimeout);
      };
    }
  });
