angular
  .module('slf')
  .component('slfUserMuteDialog', {
    templateUrl: 'app/common/user_mute_dialog/user_mute_dialog.html',
    controller: function ($rootScope, ChatManager) {
      var $ctrl = this;

      $ctrl.hide = function () {
        ChatManager.showMuteDialogForUser(null);
      };

      $ctrl.confirm = function () {
        ChatManager.muteUser($ctrl.user);
        $ctrl.hide();
      };

      var watcher = $rootScope.$watch(function () {
        return ChatManager.getMuteDialogUser();
      }, function (user) {
        $ctrl.user = user;
      });

      $ctrl.$onDestroy = function () {
        watcher();
      };
    }
  });
