angular
  .module('slf')
  .component('slfResetPasswordForm', {
    templateUrl: 'app/common/reset_password_form/reset_password_form.html',
    controller: function (UserAuth) {
      var $ctrl = this;
      $ctrl.email = '';
      $ctrl.submitted = false;

      $ctrl.reset = function () {
        UserAuth.resetPassword($ctrl.email)
          .then(function () {
            $ctrl.email = '';
            $ctrl.submitted = true;
          }, function () {
            $ctrl.errorMsg = 'Diese E-Mail-Adresse ist nicht registriert.';
          });
      };
    }
  });
