angular
  .module('slf')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('splash', {
      url: '/',
      component: 'splash'
    })
    .state('game', {
      abstract: true,
      templateUrl: 'app/game/game.html'
    })
    .state('game.lobby', {
      url: '/lobby',
      component: 'lobby'
    })
    .state('game.createRoom', {
      url: '/raum/erstellen',
      component: 'createRoom'
    })
    .state('game.room', {
      url: '/raum',
      component: 'room'
    })
    .state('game.room.waiting', {
      templateUrl: 'app/room/waiting/waiting.html'
    })
    .state('game.room.input', {
      component: 'roomInput'
    })
    .state('game.room.review', {
      component: 'roomReview'
    })
    .state('game.room.results', {
      component: 'roomResults'
    })
    .state('game.room.results.category', {
      templateUrl: 'app/room/results/category.html'
    })
    .state('game.room.results.scoring', {
      templateUrl: 'app/room/results/scoring.html'
    })
    .state('game.highscore', {
      url: '/highscore',
      component: 'highscore'
    });
}
