angular
  .module('slf')
  .factory('Users', function (ApiSocket) {
    var users = {};
    var number = 0;

    ApiSocket.on('update', function (data) {
      users = data.players;
      users['id_system']={
        id: 'id_system',
        name: 'System'
      };
      // eslint-disable-next-line guard-for-in
      for (var id in users) {
        users[id].id = users[id].cid;
        delete users[id].cid;
      }
      number = Object.keys(users).length;
    });

    return {
      getById: function (id) {
        return users[id];
      },

      getNumberOfOnline: function () {
        return number;
      }
    };
  });
