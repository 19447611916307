angular
  .module('slf')
  .component('slfChatLog', {
    templateUrl: 'app/common/chat_log/chat_log.html',
    controller: function ($rootScope, ChatManager, Rooms, $element, $timeout, Top10, Users) {
      var $ctrl = this;
      $ctrl.users = Top10.get();

      setTimeout(function () {
        document.getElementById('emojiMessage').focus();
      }, 500);

      var watcher = $rootScope.$watch(function () {
        return ChatManager.getMessages();
      }, function (messages) {
        $ctrl.messages = messages;

        $timeout(function () {
          var msgs = $element[0].querySelector('.messages');
          angular.element(msgs).scrollTopAnimated(msgs.scrollHeight, 250);
        });
      }, true);

      var watcher2 = $rootScope.$watch(function () {
        return Rooms.getCurrent();
      }, function (room) {
        $ctrl.smaller = Boolean(room);
      });

      $ctrl.handleKeyDown = function () {
        $rootScope.emojiPickerActive = false;
      };

      $ctrl.sendMessage = function () {
        ChatManager.send($ctrl.messageToSend);
        $ctrl.messageToSend = '';
        $rootScope.emojiPickerActive = false;
      };

      $ctrl.sendLocal = function (message) {
        $ctrl.messages.push(message);
      };

      $ctrl.showMuteDialog = function (user) {
        ChatManager.showMuteDialogForUser(user);
      };

      $ctrl.$onDestroy = function () {
        watcher();
        watcher2();
      };
    }
  });
