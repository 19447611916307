angular
  .module('slf')
  .component('slfPopoverForm', {
    bindings: {
      formKey: '@',
      form: '=?'
    },
    transclude: true,
    templateUrl: 'app/common/popover_form/popover_form.html',
    controller: function ($rootScope, $element, $timeout) {
      var $ctrl = this;
      var showKey;
      var watcher;

      $ctrl.hide = function () {
        $rootScope[showKey] = false;
      };

      $ctrl.$onInit = function () {
        $ctrl.formKey = $ctrl.formKey.charAt(0).toUpperCase() + $ctrl.formKey.slice(1);
        showKey = 'show' + $ctrl.formKey + 'Form';
        $ctrl.hide();

        watcher = $rootScope.$watch(showKey, function (show) {
          $element.parent().toggleClass('visible', show);
          if (show) {
            $element[0].querySelector('input').focus();
          }
        });

        $timeout(function () {
          $ctrl.form = $ctrl.popoverForm;
        });
      };

      $ctrl.$onDestroy = function () {
        watcher();
      };
    }
  });
